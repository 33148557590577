<template>
  <div>
    <button @click="isShow = !isShow">显示/隐藏</button>
    <transition-group appear>
      <h1 v-show="isShow" key="1">动画展示</h1>
      <h1 v-show="isShow" key="2">动画展示</h1>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: "Test2",
  props: [""],
  components: {},
  data() {
    return { isShow: true };
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
h1 {
  background-color: orange;
}

.v-enter,
.v-leave-to {
  transform: translateY(-100%);
}

.v-enter-to,
.v-leave {
  transform: translateY(0);
}
.v-enter-active,
.v-leave-active {
  transition: 0.5s linear;
}
</style>