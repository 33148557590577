<template>
  <div>
    <test-1></test-1>
    <v-spacer></v-spacer>
    <test-2></test-2>
    <v-spacer></v-spacer>
    <test-3></test-3>
  </div>
</template>

<script>
import Test1 from "./Test1.vue";
import Test2 from "./Test2.vue";
import Test3 from "./Test3.vue";
export default {
  name: "",
  props: [""],
  components: { Test1, Test2, Test3 },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
</style>