<template>
  <div>
    <button @click="isShow = !isShow">显示/隐藏</button>
    <transition appear>
      <h1 v-show="isShow">动画展示</h1>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Test1",
  props: [""],
  components: {},
  data() {
    return { isShow: true };
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
h1 {
  background-color: orange;
}

.v-enter-active {
  animation: act 0.5s linear;
}

.v-leave-active {
  animation: act 0.5s linear reverse;
}

@keyframes act {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0px);
  }
}
</style> 