import { render, staticRenderFns } from "./Test1.vue?vue&type=template&id=9f40d9ac&scoped=true&"
import script from "./Test1.vue?vue&type=script&lang=js&"
export * from "./Test1.vue?vue&type=script&lang=js&"
import style0 from "./Test1.vue?vue&type=style&index=0&id=9f40d9ac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f40d9ac",
  null
  
)

export default component.exports